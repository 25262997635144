"use client";

import React, { Suspense } from "react";
import markdownStyles from "../../styles/markdown-styles.module.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { cn } from "@/lib/utils";
import { useLightbox } from "@/components/context/lightbox-context";
import dynamic from "next/dynamic";

// Dynamically import SyntaxHighlighter to reduce initial bundle size
const SyntaxHighlighter = dynamic(() =>
  import("@/components/syntax").then((mod) => mod.SyntaxHighlighter),
);

const ClickableImage = ({ src, alt }) => {
  const { setIsLightboxOpen, setLightboxSrc, setClickPosition } = useLightbox();

  const handleClick = (e) => {
    // Capture the click position
    const rect = e.currentTarget.getBoundingClientRect();
    const x = rect.left + rect.width / 2;
    const y = rect.top + rect.height / 2;

    setClickPosition({ x, y });
    setLightboxSrc(src);
    setIsLightboxOpen(true);
  };

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      onClick={handleClick}
      src={src}
      alt={alt}
      className="my-0 h-auto w-full rounded-md hover:!cursor-zoom-in"
    />
  );
};

export function Markdown({
  content,
  balance = false,
  pretty = false,
  ...props
}) {
  // Component for rendering code blocks with syntax highlighting
  const CodeBlock = ({ language, value }) => {
    return (
      <div className="relative rounded-xl">
        <Suspense
          fallback={
            <div className="animate-pulse rounded-xl bg-foreground-100 p-4">
              Loading code...
            </div>
          }
        >
          <SyntaxHighlighter language={language} code={value} showCopyButton />
        </Suspense>
      </div>
    );
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={cn(
        markdownStyles.markdown,
        balance && "text-balance",
        pretty && "text-pretty",
        "prose dark:prose-invert prose-h2:mt-8 prose-a:text-foreground prose-figcaption:text-foreground-500 prose-img:rounded-lg dark:prose-a:text-foreground dark:prose-figcaption:text-foreground-400",
        props.className,
      )}
      components={{
        a: ({ node, ...a }) => {
          const id = Math.random().toString(36).substring(7);

          if (a.href.includes("mailto:"))
            return (
              <a key={id} {...a}>
                {a.children}
              </a>
            );

          if (a.href.startsWith("/")) {
            return (
              <a key={id} {...a}>
                {a.children}
              </a>
            );
          }

          return (
            <a key={id} target="_blank" rel="noopener noreferrer" {...a}>
              {a.children}
            </a>
          );
        },
        img: (node) => {
          return <ClickableImage src={node.src} alt={node.alt} />;
        },
        code: ({ inline, className, children, ...props }: any) => {
          const match = /language-(\w+)/.exec(className || "");
          const childRegex = String(children).replace(/\n$/, "");
          return !inline && match ? (
            <CodeBlock language={match[1]} value={childRegex} />
          ) : (
            <code
              className={cn(
                "rounded-md bg-foreground-100 px-1 py-0.5",
                className,
              )}
              {...props}
            >
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
